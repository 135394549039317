import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

const StyledContainer = styled.div`
  display: grid;
  background-color: rgb(209 213 219);

  @media (max-width: 1279px) {
    display: none;
  }
`

const StyledDiv = styled.div`
  background-color: rgba(255,255,255,0.7);
`

const pStyle = "text-base md:text-xl text-center mb-6"

const SectionMissionPlaceholder = ({ agentPage }) => {
  const data = useStaticQuery(graphql`
    query SectionMissionPlaceholderQuery {
      prismicHomePage {
        data {
          mission_section_title
          mission_section_text {
            richText
          }
          mission_section_text_agent {
            richText
          }
          mission_section_button_text
          mission_section_button_text_hover
          mission_section_button_link {
            link_type
            uid
            url
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePage

  return (
    <StyledContainer>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.5",
          height: "100%",
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="section background"
        src={
          "../../images/backgrounds/mission-section-bg-1.png"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
        loading="eager"
        placeholder="none"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <StyledDiv className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center mx-auto py-12 px-8 md:mt-28 md:mb-24 rounded-3xl shadow-md">
            <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto">
              <h2 className="text-white text-base md:text-2xl uppercase font-bold text-center">{doc.data.mission_section_title ? doc.data.mission_section_title : "Section Title"}</h2>
            </div>

            <div className="w-full max-w-2xl mt-12">
              {agentPage
                ?
                doc.data.mission_section_text_agent.richText
                  ?
                  <RichText render={doc.data.mission_section_text_agent.richText} htmlSerializer={htmlSerializer} />
                  :
                  <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                :
                doc.data.mission_section_text.richText
                  ?
                  <RichText render={doc.data.mission_section_text.richText} htmlSerializer={htmlSerializer} />
                  :
                  <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              }
            </div>

            <div className="flex flex-col items-center justify-center mt-6">
              <Link to={doc.data.mission_section_button_link.uid ? doc.data.mission_section_button_link.uid : "/"}>
                <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3 mb-6">
                  <span className="default-text mx-2">{doc.data.mission_section_button_text ? doc.data.mission_section_button_text : "Button Name"}</span>
                  <span className="hover-text">{doc.data.mission_section_button_text_hover ? doc.data.mission_section_button_text_hover : "Hover Name"}</span>
                </button>
              </Link>
            </div>
          </StyledDiv>
      </div>
    </StyledContainer>
  )
}

export default SectionMissionPlaceholder